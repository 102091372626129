import sendRequest from '@/libs/sendRequest';
import { createStore } from 'vuex';
import { sales } from './modules/sales';
import { program } from './modules/program.js';
import CONFIG from '../../src/page.config'
//import AES from '@/libs/AES'

export default createStore({
    state: {
        products: [],
        login: false,
        alert: {
            open: false,
            message: 'Error',
            variant: 'danger',
            color_text: 'white',
            timeout: null,
        },
        session: null,
        expired_session: false,
        current_tab: 1
    },
    getters: {
        getAlert: (state) => (state.alert),
        getCurrentTab: (state) => (state.current_tab),
        getHeaders: (state) => {
            let session = sessionStorage.getItem('wiks@session')

			if(session) {
                return {
                    ...CONFIG.server[CONFIG.env].headers,
                    session: JSON.parse(session).token 
                  }
            } else {
                return {
                    ...CONFIG.server[CONFIG.env].headers,
                }
            }

			
		},
        getSession: (state) => {
            let session = sessionStorage.getItem('wiks@session') ? JSON.parse(sessionStorage.getItem('wiks@session')) : null;
            state.session = session;
            return state.session;
        },
        getExpiredSession: (state) => (state.expired_session),
        getLogin: (state) => {
            let session = sessionStorage.getItem('wiks@session') 
            if(session) {
                state.login = true
                return state.login
            }
            else {
                state.login = false
                return state.login
            }
        }
    },
    mutations: {
        setSession: (state, session) => {
            if(!session) {
                sessionStorage.removeItem('wiks@session')
                state.session = null
            }

            else {
                //console.log(session)
                let sessionString = JSON.stringify(session);
                //console.log(sessionString);
                sessionStorage.setItem('wiks@session', sessionString);
            }
            
        },
        setCurrentTab: (state, payload) => state.current_tab = payload,

        setAlert: (state, new_alert) => {
			state.alert.open = new_alert.open
			state.alert.message = (new_alert.message !== undefined) ? new_alert.message : ''
			state.alert.variant = (new_alert.variant !== undefined) ? new_alert.variant : 'success'
			state.alert.text_color = (new_alert.text_color !== undefined) ? new_alert.text_color : 'white'
			state.alert.timeout = (new_alert.timeout !== undefined) ? new_alert.timeout : 3000

			if (state.alert.timeout !== null) {
				setTimeout(() => {
					state.alert.open = false
				}, state.alert.timeout)
			}
		},
        setLogin: (state, payload) => state.login = payload,
        setExpiredSession: (state, payload) => state.expired_session = payload
    },
    actions: {
        login: async ({ dispatch, commit}, payload) => {

            const response = await sendRequest('websales/account/login','POST', null, payload, null, null)
            
            if(response.success && response.data) {
                commit('setSession', response.data)
                await dispatch('program/listPointRedeems');
                await dispatch('sales/listFamily')
                await dispatch('sales/listBanners')
                await dispatch('sales/listOffers')
                await dispatch('sales/listBrands')
                await dispatch('sales/listOrderHistory')
                await dispatch('sales/restoreCart')
                commit('setLogin', true)
                commit('setAlert', {
                    open: true,
                    message: 'Bienvenid(a)',
                    variant: 'success',
                    text_color: 'white',
                    timeout: 3000
                })
            }

            else if(!response.success && response.errors) {
                commit('setAlert', {
                    open: true,
                    message: 'Correo o contraseña incorrectos.',
                    variant: 'danger',
                    text_color: 'white',
                    timeout: 3000
                })
            }

            else if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }
        },
        signin: async ( {commit} , body ) => {

            const response = await sendRequest('websales/account/register','POST', null, body, null, null)
            
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Registro valido',
                    variant: 'success',
                    text_color: 'white',
                    timeout: 5000
                    
                })
                //console.log(response.data)
            }
            /*else if(!response.success && response.errors) {
                commit('setAlert', {
                    open: true,
                    message: 'Error en registro',
                    variant: 'danger',
                    text_color: 'white',
                    timeout: 5000
                })
                console.log(response.errors[0].code)
            }*/
            else if(response.errors[0].code === "error_validate_cliente") {
                commit('setAlert', {
                    open: true,
                    message: 'ERROR: Error en validar cliente',
                    variant: 'danger',
                    text_color: 'white',
                    timeout: 5000
                })
            }
            else if(response.errors[0].code === "user_already_exist") {
                commit('setAlert', {
                    open: true,
                    message: 'ERROR: Ya existe el usuario',
                    variant: 'danger',
                    text_color: 'white',
                    timeout: 5000
                })
            } 
            else if(response.errors[0].code === "customer_already_exist") {
                commit('setAlert', {
                    open: true,
                    message: 'ERROR: Ya existe el cliente',
                    variant: 'danger',
                    text_color: 'white',
                    timeout: 5000
                })
            }
            else if(response.errors[0].code === "error_no_create_register") {
                commit('setAlert', {
                    open: true,
                    message: 'ERROR: No se creo registro',
                    variant: 'danger',
                    text_color: 'white',
                    timeout: 5000
                })
            } 
            else if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }
        },
        addProduct({ commit}, payload) {
            commit('setProducts', payload)
            commit('setAlert', {
                open: true,
                message: 'Producto añadido con éxito',
                variant: 'success',
                text_color: 'white',
                timeout: 3000
            })
        },
    },
    modules: {
        sales,
        program
    }
})
